:root {
  --font-size: 16px;
  --primary-color: #0052cc;
  --primary-hover-color: #013b90;
  --primary-color-alpha: rgba(0, 87, 255, 0.7);
  --dark-color: #000;
  --light-color: #fff;
  --system-gray-5: rgb(229, 229, 234);
  --system-gray-6: rgb(242, 242, 247);
  --eee-color: #eee;
  --ddd-color: #ddd;
  --transition-duration: 0.5s;
  --border-radius: 3px;
  --arrow-svg: url('../img/arrow.svg');
  --arrow-svg-width: 37px;
}

html {
  font-size: 14px;
}

html,
body,
div#root {
  height: 100%;
}

header {
  min-height: 70px;
}

p {
  line-height: 1.6;
}

h3 {
  margin: 0;
}

b {
  font-weight: 500;
}

summary,
button,
button:focus,
button:active {
  box-shadow: none !important;
  outline: none !important;
}

details {
  cursor: pointer;
  font-size: 1.3rem;
  transition: background-color var(--transition-duration);
  color: var(--light-color);
  overflow: hidden;
  user-select: none;
}

summary {
  border-radius: var(--border-radius);
  background: var(--primary-color);
  font-weight: 500;
  transition: background-color var(--transition-duration);
}

summary:hover {
  background: var(--primary-hover-color);
}

summary::marker,
summary::-webkit-details-marker {
  color: transparent;
  display: none;
  content: "";
}

summary:before {
  display: none;
  content: "";
}

details[open] summary {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

details img {
  transition: all var(--transition-duration);
}

details[open] img {
  transform: rotate(-180deg);
}

details > p {
  background: var(--primary-color-alpha);
  border-bottom-right-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
  font-weight: 500;
}

.title-one {
  font-size: 2.5rem;
  font-weight: 700;
}

.title-two {
  font-size: 2.2rem;
  font-weight: 700;
}

.title-three {
  font-size: 1.8rem;
  font-weight: 600;
}

.text-headline {
  font-size: 1.7rem;
}

.text-description {
  font-size: 1.4rem;
}

.text-secondary {
  color: #616161;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-dark {
  color: var(--dark-color);
}

.text-light {
  color: var(--light-color);
}

.text-primary {
  color: var(--primary-color);
}

.bg-system-gray-5 {
  background-color: var(--system-gray-5);
}

.link {
  text-decoration: none;
  position: relative;
  padding: 10px 0;
  font-weight: 500;
}

.link:after {
  content: "";
  display: block;
  margin: auto;
  position: absolute;
  bottom: 5px;
  height: 2px;
  width: 100%;
  border-radius: 1px;
  background: var(--light-color);;
  transition: background var(--transition-duration) ease-in-out 0s;
}

.link.text-dark:hover:after {
  background: var(--dark-color);
}

.link.text-primary:hover:after {
  background: var(--primary-color);
}

.list-style-none {
  list-style: none;
}

.text-decoration-none {
  text-decoration: none;
}

.img-responsive {
  height: auto;
  display: block;
  margin: auto;
  width: auto;
}

.btn {
  padding: 0.8rem;
  text-decoration: none;
  color: var(--light-color);
  transition: var(--transition-duration) linear;
  border-radius: 0.3rem;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
}

.btn-oval,
.btn-oval:hover {
  padding: 0.8rem 2rem;
  border-radius: 1.4rem;
}

.btn-primary {
  background: var(--primary-color);
}

.btn-soft-primary {
  background: #EBF1FF;
  color: var(--primary-color);
}

.btn-soft-primary:hover {
  background: var(--primary-color);
  color: var(--light-color);;
}
.btn-primary:hover {
  background: var(--primary-hover-color);
}

.bg-gray {
  background: #f5f5f5;
}

.border-bottom {
  border-bottom: 1px solid #eee;
}

.radius-5px {
  border-radius: 5px;
}

.ios-max-height {
  max-height: 600px;
}

.h-100 {
  height: 100%;
}

.h-30px {
  height: 30px;
}

.div-details:hover {
  background: var(--system-gray-6);
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }

  header {
    min-height: 110px;
  }

  details > p {
    padding-left: 5rem;
  }
  .title-one {
    font-size: 3.5rem;
  }

  .title-five {
    font-size: 1.1rem;
  }

  .text-md-center {
    text-align: center;
  }

  .text-md-left {
    text-align: left;
  }
}
